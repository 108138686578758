import React from 'react';
import styled from 'styled-components';
import { DEVICE } from '../../../styles/constants';
import { COLORS } from '../../../styles/theme';
import AkamaiImage from '../../Common/AkamaiImage';
import Icon, { Icons } from '../../Common/Icon';
import {
  BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE,
  BLOCK_VIEW_TITLE_CARD_SIZE,
  IMAGE_ASPECT,
} from '../constants';

const desktopSize = BLOCK_VIEW_TITLE_CARD_SIZE.linear;
const mobileSize = BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE.linear;

const PlayIconContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  display: flex;
  width: 48px;
  height: 48px;
  margin: auto;
  justify-content: center;
  border-radius: 50%;
  box-sizing: border-box;
  transition: all 0.15s ease-in;
  border: 1px solid transparent;
  background-color: ${COLORS.charcoal_overlay_50};

  > div {
    width: 28px;
  }
`;

const Container = styled.div`
  position: relative;
  width: ${desktopSize.width}px;
  overflow: hidden;
  border-radius: 10px;
  background: ${COLORS.ink};
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    border: 1px solid ${COLORS.white_10};
    z-index: 1;
  }

  &:hover ${PlayIconContainer} {
    color: ${COLORS.black};
    border: 1px solid ${COLORS.black};
    background-color: ${COLORS.white};
  }

  @media ${DEVICE.mobileWide} {
    width: ${mobileSize.width}px;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
  background-color: ${COLORS.gray_3};
  padding-bottom: ${100 / IMAGE_ASPECT.live}%;
  flex-shrink: 0;

  img {
    width: 100%;
    height: auto;
    position: absolute;
  }
`;

const BottomContainer = styled.div`
  height: ${desktopSize.height -
  desktopSize.width /
    IMAGE_ASPECT.live}px; // カードの高さから画像の高さを引いた値
  padding: 8px 12px 0;
  box-sizing: border-box;

  @media ${DEVICE.mobileWide} {
    height: ${mobileSize.height - mobileSize.width / IMAGE_ASPECT.live}px;
  }
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media ${DEVICE.mobileWide} {
    font-size: 14px;
  }
`;

interface ChannelTitleCardProps {
  title: string;
  thumbnailUrl: string;
}

const ChannelCard: React.FC<ChannelTitleCardProps> = ({
  title,
  thumbnailUrl,
}) => {
  const akamaiProps = {
    src: thumbnailUrl,
    availableSizes: [190, 380],
    sizeConfig: {
      SD: {
        width: desktopSize.width,
      },
      MOBILE_WIDE: {
        width: mobileSize.width,
      },
    },
    alt: title,
  };

  return (
    <Container data-ucn="channel-container">
      <ImageContainer>
        <AkamaiImage {...akamaiProps} />
        <PlayIconContainer>
          <Icon name={Icons.PLAY} />
        </PlayIconContainer>
      </ImageContainer>
      <BottomContainer>
        <Title>{title}</Title>
      </BottomContainer>
    </Container>
  );
};

export default ChannelCard;
