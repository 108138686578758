import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import {
  cosmo_genreQuery,
  cosmo_genreQueryVariables,
} from '../../__generated__/globalTypes';
import LegacyIcon, {
  LegacyIcons,
} from '../../shared/components/Common/Icon/LegacyIcon';
import MetaTags from '../../shared/components/MetaTags';
import Slider from '../../shared/components/Slider';
import ChannelCard from '../../shared/components/TitleCard/Channel';
import CharacterCard from '../../shared/components/TitleCard/Character';
import { LINEAR_INFO_URL } from '../../shared/constants';
import useClientQuery from '../../shared/hooks/useClientQuery';
import useInteraction from '../../shared/hooks/useInteraction';
import type { VideoGenreTopCharacterBlockCharacterThumbLog } from '../Log/__types__/videoGenreTop-characterBlock-characterThumb';
import type { VideoGenreTopCharacterBlockMoreLog } from '../Log/__types__/videoGenreTop-characterBlock-more';
import { getKafkaClient } from '../Log/kafkaClient';
import BlockHeading from './BlockHeading';
import BlockTitle from './BlockTitle';
import {
  LIVES_PER_BLOCK,
  MAX_CHARACTER_BLOCK_SIZE,
  PAGE_SIZE,
  TITLES_PER_BLOCK,
} from './constants';
import { GET_GENRE } from './gql';
import LeagueBlock from './LeagueBlock';
import LiveBlock from './LiveBlock';
import { messages as genreMessages } from './messages';
import RecommendedBlocks, { BlockContainer } from './RecommendedBlocks';
import SliderPlaceholder from './SliderPlaceholder';
import { isActive } from './utils';

const InfoIcon = styled(LegacyIcon)`
  border-radius: 100%;

  > svg {
    border-radius: 100%;
    top: 0.05em;
    color: ${({ theme }) => theme.genre.infoIcon};
    transition: color 0.15s ease-in;
  }
`;

const InfoLink = styled.a`
  margin-left: 8px;

  &:hover > ${InfoIcon} > svg {
    color: ${({ theme }) => theme.genre.infoIconHover};
  }
`;

/**
 * This will be deprecated and replaced with VideoRecommendBlocks someday
 */
export const VideoGenreBlocks: React.FC<{
  genreCode?: string;
  genrePath?: string;
  pageNumber?: number;
  baseLink: string;
  setFirstRowImages?: (images: string[]) => void;
}> = ({
  genreCode,
  pageNumber = 1,
  genrePath,
  baseLink,
  setFirstRowImages,
}) => {
  const isTouch = useInteraction();
  const intl = useIntl();

  const [didFetchMore, setDidFetchMore] = useState(false);

  const { data, loading, fetchMore } = useClientQuery<
    cosmo_genreQuery,
    cosmo_genreQueryVariables
  >(GET_GENRE, {
    variables: {
      genreId: genreCode || '',
      page: pageNumber,
      pageSize: PAGE_SIZE,
      titlesPerBlock: TITLES_PER_BLOCK,
      livesPerBlock: LIVES_PER_BLOCK,
    },
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
  });

  const fetchPageData = (page: number) => {
    setDidFetchMore(true);
    return fetchMore({
      variables: { page },
    });
  };
  useEffect(() => {
    if (
      setFirstRowImages &&
      data?.webfront_recommendBlocks?.recommendBlocks?.length
    ) {
      const firstRowImages =
        data?.webfront_recommendBlocks.recommendBlocks[0].titles.map(
          (t) => t.thumbnail?.secondary ?? ''
        );
      setFirstRowImages(firstRowImages);
    }
  }, [data?.webfront_recommendBlocks?.recommendBlocks, setFirstRowImages]);

  return (
    <>
      <MetaTags
        link={baseLink}
        pageNumber={pageNumber}
        pages={data?.webfront_recommendBlocks?.pageInfo?.pages}
      />
      {pageNumber === 1 &&
        data?.getSakuhinGroupBlock &&
        data.getSakuhinGroupBlock.length > 0 && (
          <BlockContainer key="character-block" data-ucn="characterBlock">
            <BlockHeading
              hasNoLead
              href={`/characters/${genrePath}`}
              title={intl.formatMessage(genreMessages.characterBlockHeading)}
              onClick={() => {
                getKafkaClient().trackUserClickDimension0<VideoGenreTopCharacterBlockMoreLog>(
                  'videoGenreTop-characterBlock-more',
                  {}
                );
              }}
            />
            <Slider
              type="character"
              keyPrefix="character-block-slider"
              items={({ isSliding, activeRange }) =>
                data.getSakuhinGroupBlock
                  .slice(0, MAX_CHARACTER_BLOCK_SIZE)
                  .map(({ code, name, thumbnail }, index) => (
                    <Link
                      key={code}
                      href={`/browse/character/${code}`}
                      tabIndex={isActive(index, activeRange) ? undefined : -1}
                      onClick={() => {
                        getKafkaClient().trackUserClickDimension1<VideoGenreTopCharacterBlockCharacterThumbLog>(
                          'videoGenreTop-characterBlock-characterThumb',
                          {
                            index,
                            kids_group_code: code,
                            kids_group_list: data.getSakuhinGroupBlock
                              .slice(0, index)
                              .map((g) => ({
                                kids_group_code: g.code,
                              })),
                          }
                        );
                      }}
                      legacyBehavior={false}
                      data-ucn="characterBlock-item-link"
                    >
                      <CharacterCard
                        thumbnailUrl={`//${thumbnail.circle}`}
                        name={name}
                        isDisabled={isTouch || isSliding}
                      />
                    </Link>
                  ))
              }
            />
          </BlockContainer>
        )}
      {pageNumber === 1 &&
        data?.leagueBlock &&
        data.leagueBlock.list.length > 0 && (
          <LeagueBlock {...data.leagueBlock} />
        )}
      {pageNumber === 1 &&
        data?.liveBlocks.blockList &&
        data.liveBlocks.blockList.map((liveBlock, rowIndex) => {
          return (
            <LiveBlock
              key={liveBlock.code}
              genreCode={genreCode}
              liveBlock={liveBlock}
              rowIndex={rowIndex}
              context={genreCode ? 'videoGenreTop' : 'home'}
              isLazy={false}
            />
          );
        })}
      {pageNumber === 1 &&
        data?.webfront_linearChannels &&
        data.webfront_linearChannels.length > 0 && (
          <BlockContainer key={`channel-block`} data-ucn="channelBlock">
            <BlockTitle.StyledLink as="div" hasNoLead>
              <BlockTitle.LinkText>
                {intl.formatMessage(genreMessages.genreRecommendedChannels)}
              </BlockTitle.LinkText>
              <InfoLink
                href={LINEAR_INFO_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <InfoIcon name={LegacyIcons.PURCHASE_INFO} />
              </InfoLink>
            </BlockTitle.StyledLink>
            <Slider
              type="linear"
              keyPrefix="channel-slider"
              items={({ activeRange }) =>
                data.webfront_linearChannels.map((channel, index) => {
                  return (
                    <Link
                      key={channel.id}
                      data-ucn="channelBlock-item-link"
                      href={`/realtime/${channel.id}`}
                      tabIndex={isActive(index, activeRange) ? undefined : -1}
                      legacyBehavior={false}
                    >
                      <ChannelCard
                        key={channel.id}
                        thumbnailUrl={`//${channel.thumbnail.standard}`}
                        title={channel.name}
                      />
                    </Link>
                  );
                })
              }
            />
          </BlockContainer>
        )}
      {loading &&
        !data?.webfront_recommendBlocks &&
        [...Array(PAGE_SIZE)].map((_item, i) => (
          <SliderPlaceholder key={`default-placeholder-${i}`} />
        ))}
      {data?.webfront_recommendBlocks && (
        <RecommendedBlocks
          data={data}
          loading={loading}
          fetchPageData={fetchPageData}
          didFetchMore={didFetchMore}
          leanbackGenreCode={genreCode || 'home'}
        />
      )}
    </>
  );
};
