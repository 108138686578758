import Link from 'next/link';
import React from 'react';
import { cosmo_genreQuery } from '../../../__generated__/globalTypes';
import Slider, {
  COUNT_BLOCK_CLASS_NAME,
} from '../../../shared/components/Slider';
import SimpleCard from '../../../shared/components/TitleCard/Simple';
import type { VideoGenreTopLeagueBlockLeagueCardLog } from '../../Log/__types__/videoGenreTop-leagueBlock-leagueCard';
import type { VideoGenreTopLeagueBlockPageNextLog } from '../../Log/__types__/videoGenreTop-leagueBlock-pageNext';
import type { VideoGenreTopLeagueBlockPagePrevLog } from '../../Log/__types__/videoGenreTop-leagueBlock-pagePrev';
import { getKafkaClient } from '../../Log/kafkaClient';
import BlockTitle from '../BlockTitle';
import { BlockContainer } from '../RecommendedBlocks';
import { getKafkaLogRowIndex, isActive } from '../utils';

const LeagueBlock: React.FC<NonNullable<cosmo_genreQuery['leagueBlock']>> = ({
  name,
  list,
}) => {
  const keyPrefix = 'league-block-slider';
  const rowIndex = getKafkaLogRowIndex(COUNT_BLOCK_CLASS_NAME, keyPrefix);
  return (
    <BlockContainer key={`league-block`} data-ucn="leagueBlock">
      <BlockTitle.StyledLink as="div" hasNoLead>
        <BlockTitle.LinkText>{name}</BlockTitle.LinkText>
      </BlockTitle.StyledLink>
      <Slider
        type="league"
        keyPrefix={keyPrefix}
        items={({ activeRange }) => {
          const items = list.map((league, index) => {
            return (
              <Link
                key={league.code}
                href={{
                  pathname: `/league/${league.code}`,
                }}
                tabIndex={isActive(index, activeRange) ? undefined : -1}
                onClick={() => {
                  getKafkaClient().trackUserClickDimension2<VideoGenreTopLeagueBlockLeagueCardLog>(
                    'videoGenreTop-leagueBlock-leagueCard',
                    {
                      column_index: index,
                      subject_genre_code: league.tagCode,
                      row_index: rowIndex,
                    }
                  );
                }}
                legacyBehavior={false}
                data-ucn="leagueBlock-item-link"
              >
                <SimpleCard
                  thumbnailUrl={`//${league.thumbnail.standard}`}
                  name={league.name}
                  type="league"
                />
              </Link>
            );
          });
          return items;
        }}
        onClickLeftButton={() =>
          getKafkaClient().trackUserClickDimension1<VideoGenreTopLeagueBlockPagePrevLog>(
            'videoGenreTop-leagueBlock-pagePrev',
            {
              index: rowIndex,
            }
          )
        }
        onClickRightButton={() =>
          getKafkaClient().trackUserClickDimension1<VideoGenreTopLeagueBlockPageNextLog>(
            'videoGenreTop-leagueBlock-pageNext',
            {
              index: rowIndex,
            }
          )
        }
      />
    </BlockContainer>
  );
};

export default LeagueBlock;
