import React from 'react';
import styled from 'styled-components';
import { DEVICE } from '../../../styles/constants';
import { COLORS } from '../../../styles/theme';
import { default as AkamaiImage } from '../../Common/AkamaiImage';
import {
  BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE,
  BLOCK_VIEW_TITLE_CARD_SIZE,
  IMAGE_ASPECT,
} from '../constants';

type SimpleCardType = 'label' | 'league' | 'spotlight';

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(35, 45, 58, 1) 0%,
    rgba(20, 30, 43, 1) 100%
  );
  padding-bottom: ${100 / IMAGE_ASPECT.video}%;
  flex-shrink: 0;

  img {
    width: 100%;
    height: auto;
    position: absolute;
  }
`;

const Layout = styled.div<{ type: SimpleCardType }>`
  position: relative;
  width: ${({ type }) => BLOCK_VIEW_TITLE_CARD_SIZE[type].width}px;
  overflow: hidden;
  border-radius: 10px;
  transition: transform 200ms ease-in, z-index 100ms 100ms linear;
  background: ${COLORS.ink};
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    border: 1px solid ${COLORS.white_10};
    z-index: 1;
  }

  @media (hover: hover) {
    &:hover {
      transform: scale(1.1);
      z-index: 500;
      box-shadow: 0px 8px 32px 0px ${COLORS.jet_black};
    }
  }

  @media ${DEVICE.mobileWide} {
    width: ${({ type }) => BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE[type].width}px;
  }
`;

const SimpleCard: React.FC<{
  thumbnailUrl: string;
  name: string;
  type: SimpleCardType;
}> = ({ thumbnailUrl, name, type }) => {
  return (
    <Layout type={type}>
      <ImageContainer>
        <AkamaiImage
          src={thumbnailUrl}
          alt={name}
          sizeConfig={{
            SD: { width: BLOCK_VIEW_TITLE_CARD_SIZE[type].width },
            MOBILE_WIDE: {
              width: BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE[type].width,
            },
          }}
        />
      </ImageContainer>
    </Layout>
  );
};

export default SimpleCard;
