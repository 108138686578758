import React, { useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { DEVICE } from '../../../styles/constants';
import { default as AkamaiImage } from '../../Common/AkamaiImage';
import {
  BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE,
  BLOCK_VIEW_TITLE_CARD_SIZE,
} from '../constants';

const StyledAkamaiImage = styled(AkamaiImage)`
  width: 100%;
`;

const Name = styled.h3<{ show: boolean }>`
  margin: 16px 0 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 0.15s ease-in;

  @media ${DEVICE.mobileWide} {
    display: none;
  }
`;

const Layout = styled(animated.div)`
  &&& {
    position: relative;
    width: ${BLOCK_VIEW_TITLE_CARD_SIZE.character.width}px;

    @media ${DEVICE.mobileWide} {
      width: ${BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE.character.width}px;
    }
  }
`;

const CharacterCard: React.FC<{
  thumbnailUrl: string;
  name: string;
  isDisabled?: boolean;
}> = ({ thumbnailUrl, name, isDisabled }) => {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (isDisabled) {
      setIsHovered(false);
    }
  }, [isDisabled]);

  const scaleProps = useSpring({
    transform: isHovered ? 'scale(1.1)' : 'scale(1)',
    zIndex: isHovered ? 2000 : 100,
    config: { duration: 200 },
  });

  return (
    <>
      <Layout
        style={{
          transform: scaleProps.transform,
          zIndex: scaleProps.zIndex,
        }}
        onMouseEnter={isDisabled ? undefined : () => setIsHovered(true)}
        onMouseLeave={isDisabled ? undefined : () => setIsHovered(false)}
        onFocus={isDisabled ? undefined : () => setIsHovered(true)}
        onBlur={isDisabled ? undefined : () => setIsHovered(false)}
      >
        <StyledAkamaiImage
          src={thumbnailUrl}
          sizeConfig={{
            SD: { width: BLOCK_VIEW_TITLE_CARD_SIZE.character.width },
            MOBILE_WIDE: {
              width: BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE.character.width,
            },
          }}
          alt={name}
        />
      </Layout>
      <Name show={isHovered}>{name}</Name>
    </>
  );
};

export default CharacterCard;
