import { useEffect, useState } from 'react';

export const INTERACTION_TYPE = {
  MOUSE: 'mouse',
  PEN: 'pen',
  TOUCH: 'touch',
};

const UPDATE_INTERVAL = 1000; // Throttle updates to the type to prevent flip flopping

/**
 * Interaction Detection is confusing
 *
 * Browsers tend to act different ways in different cases for different
 * inputs. The job of this InteractionProvider is to keep track of those changes
 * of input types and let the app know through context if the type has changed.
 *
 *
 * Why are we binding onMouseMove and onPointerDown/Move when the pointer API
 * extends mouse events?
 * -----------------------------
 * This is because Edge does not support touch events, but all other browsers do.
 * Also, Safari doesn't support pointer events, but all other browsers do.
 * So we can make the determination that in the case that touch events _are_
 * available, we will bind mouse and touch events only. If we don't have touch events,
 * we will only bind pointer events.
 *
 * In this case, desktops with no touch interaction don't need anything but defaulting
 * to mouse all the time anyways. Mobile devices that are only touch will always have
 * touch events available and will default to isTouch: true and never change. But in the
 * case of tablets that support multiple input types, we need these bindings to be dynamic.
 *
 *
 * Why is there a timeout in onMouseMove and why is the timeout time the same as the
 * the time we check in shouldUpdate? Won't this always evaluate to false?
 * -----------------------------
 * Not necessarily. This is because we update `lastTouchUpdate` every time there is a
 * touch event, even if the state is already set to true. This way if _another_ touch
 * event happens in the middle of the timeout set by `onMouseMove`, it will end up
 * being cancelled.
 *
 *
 * Why is a timeout necessary in the first place if we are getting touch OR mouse events?
 * -----------------------------
 * Actually, we can't assume we will only get one type of event. On a Surface tablet using
 * Chrome for example, both the onMouseMove _and_ onTouchStart are both triggered on
 * every touch. The touch event also always gets fired right before the mouse event. We
 * have to throttle the change or it will never actually get set to touch.
 *
 */

const useInteraction = (): boolean => {
  const [isTouch, setIsTouch] = useState(false);

  useEffect(() => {
    const hasTapEvent = 'ontouchstart' in window;

    /**
     * for Oculus Quest
     * Oculus browser also has both touchstart and mousemove events,
     * but there is no need to change isTouch dynamically.
     */
    const isTouchDeviceWithHoverSupport = window.matchMedia(
      '(hover: hover) and (pointer: coarse)'
    ).matches;

    setIsTouch(hasTapEvent && !isTouchDeviceWithHoverSupport);

    let localTouch = hasTapEvent;
    let lastTouchUpdate = Date.now();

    const shouldUpdate = (): boolean =>
      lastTouchUpdate + UPDATE_INTERVAL < Date.now();

    const onMouseMove = (): void => {
      if (localTouch && shouldUpdate()) {
        setTimeout(() => {
          if (shouldUpdate()) {
            setIsTouch(false);
            localTouch = false;
          }
        }, UPDATE_INTERVAL);
      }
    };

    const onTouchStart = (): void => {
      lastTouchUpdate = Date.now();

      if (!localTouch) {
        setIsTouch(true);
        localTouch = true;
      }
    };

    const onPointerMove = (e: PointerEvent): void => {
      const { pointerType } = e;

      switch (pointerType) {
        case INTERACTION_TYPE.TOUCH:
        case INTERACTION_TYPE.PEN:
          return onTouchStart();
        default:
          return onMouseMove();
      }
    };

    if (hasTapEvent) {
      window.addEventListener('mousemove', onMouseMove);
      if (!isTouchDeviceWithHoverSupport) {
        window.addEventListener('touchstart', onTouchStart);
      }
    } else {
      window.addEventListener('pointerdown', onPointerMove);
      window.addEventListener('pointermove', onPointerMove);
    }

    return () => {
      if (hasTapEvent) {
        window.removeEventListener('mousemove', onMouseMove);
        if (!isTouchDeviceWithHoverSupport) {
          window.removeEventListener('touchstart', onTouchStart);
        }
      } else {
        window.removeEventListener('pointerdown', onPointerMove);
        window.removeEventListener('pointermove', onPointerMove);
      }
    };
  }, []);

  return isTouch;
};

export default useInteraction;
